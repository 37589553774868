import Cookies from 'js-cookie'

jQuery(document).ready(function ($) {

    if (window.data.thank_you) {
        Cookies.set('metrodontshow', true, { expires: 365 })
    }

    $.modal({
        opener: '.signupPop',
        modal: '#signup',
        onBeforeClose: function () {
            let now = Math.round((new Date()).getTime() / 1000)
            
            // unix timestamp
            Cookies.set('metrodelayshow', now, { expires: 365 })
        }
    })

    function dontShow() {
        Cookies.set('metrodontshow', true, { expires: 365 })
    }

    $('.btn-dont-show').click(function () {
        dontShow();
    })

    var show = Cookies.get('metrodontshow')

    if (show == undefined) {

        var delayVariable = $('#signup').data('delay')
        var delayCalculate = delayVariable * 1000

        setTimeout(startOffer, Number(delayCalculate))
        
        function startOffer() {
            let timestamp = Cookies.get('metrodelayshow'),
                now = Math.round((new Date()).getTime() / 1000)

            timestamp = timestamp != undefined ? Number(timestamp) : timestamp
            if (timestamp == undefined) { // if 2 minutes has elapsed since modal was closed
                document.getElementById("jsClick") ? document.getElementById("jsClick").click():"" // Click on the checkbox
            } else if (now - timestamp > 120) { // if the modal has never been closed
                document.getElementById("jsClick") ? document.getElementById("jsClick").click():"" // Click on the checkbox
            }
        }
    }

});